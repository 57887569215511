import { Component, OnInit } from '@angular/core';
import {CmsService} from '../../services/cms.service';
import {TranslateService} from '@ngx-translate/core';
import {LoginService} from "../../services/login.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private cmsService: CmsService, private loginService: LoginService, public translateService: TranslateService) { }

  previewCategory;

  homeText:any;


  ngOnInit(): void {
    this.cmsService.getCategoryById('1', this.loginService.token).subscribe(
        data => this.previewCategory = data
    );

    this.cmsService.getHomeText().subscribe(data => {
      this.homeText = data[0];
      //console.log(data);
    })


  }

}
