// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import {environment as environmentDefault} from './environment.default';



const protocol = window.location.protocol;
const hostname = window.location.hostname;
let port = window.location.port ? `:${window.location.port}` : '';
const wsProtocol = protocol === 'https:' ? 'wss:' : 'ws:';

export const environment = {
  ...environmentDefault,
  log : true,
  // apiUrl: 'http://52.209.137.49/',
  //apiUrl: 'http://eis-iraq.org/eis_iraq/',
  apiUrl: `${protocol}//${hostname}${port}/eis_iraq/`,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
