import {AfterViewInit, Component, Input, OnInit, SimpleChanges} from '@angular/core';
import * as ChartJs from 'chart.js';
import { v4 as uuidv4 } from 'uuid';
import {Chart} from '../../../interfaces/chart';
import {SerieService} from '../../../services/serie.service';
import * as _ from 'lodash';
import {ConfigService} from '../../../services/config.service';
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'app-horizontal-bar-chart',
  templateUrl: './horizontal-bar-chart.component.html',
  styleUrls: ['./horizontal-bar-chart.component.scss']
})
export class HorizontalBarChartComponent implements OnInit {

  @Input() chartObj: Chart;

  uid;
  canvas;
  ctx;
  Chart;
  series;
  parsedData;
  currentLang;

  constructor(private serieService: SerieService, private configService: ConfigService, private translateService: TranslateService) {
    this.uid = uuidv4();
  }

  ngOnInit() {
    //console.log('data HORIZONTAL', this.chartObj)
  }

  loadingChart = false

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    //console.log('load horizontal bar chart');
    this.currentLang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(lang => {
      this.currentLang = lang.lang;
    });
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnChanges(change: SimpleChanges) {
    if (change.chartObj) {
      this.loadSeries();

    }

  }
  canPrint() {
    return this.configService.canPrintChart();
  }
  canExportData() {
    return this.configService.canDowloadCsv();
  }
  exportData() {

    // Helper per eseguire l'escape delle stringhe
    const escapeCsvValue = (value: string | number) => {
      const stringValue = String(value);
      if (stringValue.includes(',') || stringValue.includes('"') || stringValue.includes('\n')) {
        // Raddoppia i doppi apici e racchiudi tutto tra doppi apici
        return `"${stringValue.replace(/"/g, '""')}"`;
      }
      return stringValue;
    };

    // Inizializza il contenuto del CSV con l'intestazione
    let csvContent = 'data:text/csv;charset=utf-8,\n';
    csvContent += ['Label', ...this.parsedData.labels.map(escapeCsvValue)].join(',') + '\n';

    // Aggiungi i dati dei dataset riga per riga, preceduti dalla rispettiva label
    this.parsedData.datasets.forEach(dataset => {
      csvContent += [escapeCsvValue(dataset.label), ...dataset.data.map(escapeCsvValue)].join(',') + '\n';
    });

    // Chiama il metodo per scaricare il CSV
    this.configService.downloadDataInCsv(csvContent, this.chartObj['name_' + this.currentLang]);
  }

  print(id) {
    this.configService.print(id , this.chartObj.name_en, '#fff' );
  }


  parseData(selectedSeries) {

    const labels = selectedSeries.labels;
    const datasets = [];

    let isMultiBar = true; /*TRATTIAMO TUTTI COME MULTI BAR*/

/*    if (Array.isArray(selectedSeries.values[Object.keys(selectedSeries.values)[0]])) {
      isMultiBar = true;
    }*/

    let backgroundColor = [];
    if (this.chartObj.configuration.hasOwnProperty('colors')) {
      backgroundColor = this.chartObj.configuration.colors;
    } else {
      selectedSeries.labels.map(serie => {
        backgroundColor.push('#' + (0x1000000 + (Math.random()) * 0xffffff).toString(16).substr(1, 6));
      });
    }

    if (isMultiBar) {
      Object.keys(selectedSeries.values).map((value, index) => {
        //console.log('d', selectedSeries.values[value])
        let dataset = undefined
        if (selectedSeries.values[value].filter(x => this.numDigitsAfterDecimal(x)==3).length > 0) {
          dataset = {
            label: value,
            data: selectedSeries.values[value].map(d => d.toFixed(3)),
            backgroundColor: backgroundColor[index]
          }
        } else if (selectedSeries.values[value].filter(x => this.numDigitsAfterDecimal(x)==2).length > 0){
             dataset = {
              label: value,
              data: selectedSeries.values[value].map(d => d.toFixed(2)),
              backgroundColor: backgroundColor[index]
            };
        }
        else if (selectedSeries.values[value].filter(x => this.numDigitsAfterDecimal(x)==1).length > 0){
           dataset = {
            label: value,
            data: selectedSeries.values[value].map(d => d.toFixed(1)),
            backgroundColor: backgroundColor[index]
          };
        } else {
           dataset = {
            label: value,
            data: selectedSeries.values[value].map(d => d.toFixed()),
            backgroundColor: backgroundColor[index]
          };
        }

        datasets.push(dataset);
      });


      this.parsedData = {
        labels,
        datasets
      };
    } else {
      this.parsedData = {
        labels,
        datasets: [{
          label: 'label',
          data: selectedSeries.values.map(d => d.toFixed(1)),
          backgroundColor: backgroundColor[0]
        }]
      };

    }


  }



  numDigitsAfterDecimal(x) {
    var afterDecimalStr = x.toString().split('.')[1] || ''
    return afterDecimalStr.length
  }



  loadSeries() {
    this.loadingChart = true
    this.serieService.getSerieById(this.chartObj.configuration.serie).subscribe(
        data => {
          console.log('data', data)
          this.series = data[0].data;
          this.loadingChart = false
          //se serie contiene più oggetti
          if (this.series.length>0) {
            this.selectedSeries = this.series[0].name
            this.parseData(this.series[0])
          }
            //altrimenti carico grafico semplice
          else this.parseData(this.series)
          this.loadChart();
        }
    );
  }

  selectedSeries
  changeSerie(newSerieName){
    this.parsedData = []
    let newSerie = this.series.find(x => x.name == newSerieName)
    this.parseData(newSerie)
    this.loadChart();
  }

  numberWithCommas (x) {
    return x.toString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  loadChart() {

    let displayLegend: boolean = this.parsedData.datasets.length > 1


    if(this.Chart!=null){
      this.Chart.destroy();
    }

    this.canvas = document.getElementById(this.uid);
    this.ctx = this.canvas.getContext('2d');
    this.Chart = new ChartJs(this.ctx, {
      type: 'horizontalBar',
      data: this.parsedData,
      options: {
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true,
              callback: function(value, index, ticks) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");;
              }
            },
            scaleLabel: {
              display: (this.chartObj.configuration.hasOwnProperty('label_x')),
              labelString: (this.chartObj.configuration.hasOwnProperty('label_x')) ? this.chartObj.configuration.label_x : ''
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            min: 0,
            //offset: true,
            scaleLabel: {
              display: (this.chartObj.configuration.hasOwnProperty('label_y')),
              labelString: (this.chartObj.configuration.hasOwnProperty('label_y')) ? this.chartObj.configuration.label_y : ''
            }
          }]
        },
        legend: {
          display: displayLegend
        },
        maintainAspectRatio: false,
        display: true,
        tooltips: {
          enabled: true,
          mode: 'nearest',
          callbacks:  {
            label: (tooltipItems, data) => {
              // console.log(data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]);
              // console.log(this.chartObj.configuration.hasOwnProperty('um'));
              const um = (this.chartObj.configuration.hasOwnProperty('um')) ? this.chartObj.configuration.um : '';
              return data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] + ' ' + um;
            }
          }
        }
      }
    });
  }

}
