import {Component, OnInit} from '@angular/core';
import {CmsService} from '../../services/cms.service';
import {map, switchMap, tap} from 'rxjs/operators';
import {SerieService} from '../../services/serie.service';
import {ActivatedRoute} from '@angular/router';
import domtoimage from 'dom-to-image';
import {ConfigService} from '../../services/config.service';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin} from 'rxjs';
import {CustomPrintService} from "../../services/custom-print.service";
import {ChartDialogComponent} from "../../components/chart-dialog/chart-dialog.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CustomPrintCartComponent} from "../../components/custom-print-cart/custom-print-cart.component";
import {LoginService} from "../../services/login.service";


@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    hideShowSidebar = true;

    selectedCategory;
    category;
    resources$;
    resources;
    categories$;

    isPrintingDashboard = false;

    currentLang = 'en';


    userRoles;

    tileExpanded = false;

    // DEMO PER FILTRO

    selectedItems = [];

    // closeBB
    closeBB(elementID) {
        document.getElementById(elementID).classList.add('d-none');
    }

    toggleSidebar() {
        this.hideShowSidebar = !this.hideShowSidebar;
    }

    // hideshow le info
    toggleInfo(elementID) {
        if (document.getElementById(elementID).classList.contains('hideInfo')) {
            document.getElementById(elementID).classList.remove('hideInfo');
            document.getElementById(elementID).classList.add('showInfo');
        } else {
            document.getElementById(elementID).classList.remove('showInfo');
            document.getElementById(elementID).classList.add('hideInfo');
        }
    }

    // espande contrae la tile

    toggleExpand(elementID) {
        this.tileExpanded = !this.tileExpanded;
        if (document.getElementById(elementID).classList.contains('col-lg-6')) {
            document.getElementById(elementID).classList.remove('col-lg-6');
            document.getElementById(elementID).classList.add('col-12');
        } else {
            document.getElementById(elementID).classList.remove('col-12');
            document.getElementById(elementID).classList.add('col-lg-6');
        }
    }

    selectCategory(category) {
        this.selectedCategory = category;
        //add category id to browser url bar
        window.history.replaceState({}, '', '/#/dashboard/' + category.id);
    }

    canPrintDashboard() {
        return this.configService.canPrintDashboard();
    }

    print(id) {
        this.isPrintingDashboard = true;
        domtoimage.toJpeg(document.getElementById(id), {quality: 0.95})
            .then((dataUrl) => {
                const link = document.createElement('a');
                link.download = 'dashboard.jpeg';
                link.href = dataUrl;
                link.click();
                this.isPrintingDashboard = false;
            });
    }

    printPdf() {

        const aRequest = this.resources.map((resource) => {
            return this.cmsService.getFullResourceByTypeAndId(resource.resource_type, resource.id).pipe(map((data ) => {
                // @ts-ignore
                data.resource_type = resource.resource_type;
                // @ts-ignore
                data.id = resource.id;
                return data;
            }));
        });
        forkJoin(aRequest).subscribe(data => this.configService.donwloadExportedPdf(data, this.currentLang));


    }

    loadPrintDashBoard() {
        let linkattribure = '';
        if(this.resources) linkattribure += this.resources.map((resource) => {
            return resource.resource_type + ';' + resource.id ;
        });
        return linkattribure;
    }

    printDashboardView() {
        const url = window.location.origin + '/#/print-dashboard?category=' + this.selectedCategory.id + '&lang=' + this.currentLang + '&preselected_resources=' + this.loadPrintDashBoard();

        // tslint:disable-next-line:max-line-length
        this.configService.getPdfFromApps(url)
            .subscribe(data => {
                console.log(data);
            });
    }

    exportData(resource) {

        // this.serieService.exportData(resource).subscribe();

        this.cmsService.getFullResourceByTypeAndId('graph', resource.id).pipe(
            // @ts-ignore
            switchMap(fullResource => this.serieService.getSerie(fullResource.configuration.serie)),
            map(data => {

                let csvContent = 'data:text/csv;charset=utf-8,';
                csvContent += data.labels.join(',') + '\n';

                if (Object.keys(data.values).length > 0 && !Array.isArray(data.values)) {

                    Object.keys(data.values).map(val => {
                        csvContent += data.values[val].join(',') + '\n';
                    });

                } else {
                    csvContent += data.values.join(',');
                }
                const encodedUri = encodeURI(csvContent);
                window.open(encodedUri);
            })
        ).subscribe();
    }

    gotoTop() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    // tslint:disable-next-line:max-line-length
    constructor(private cmsService: CmsService,
                private serieService: SerieService,
                private activatedRoute: ActivatedRoute,
                private configService: ConfigService,
                private translateService: TranslateService,
                public customPrintService: CustomPrintService,
                private loginService: LoginService,
                private modalService: NgbModal,
    ) {

    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.translateService.onLangChange.subscribe(lang => {
            this.currentLang = lang.lang;
        });

        this.activatedRoute.params.subscribe(param => {
            if (param.categoryId) {
                this.categories$ = this.cmsService.getCategories(this.loginService.token).pipe(
                    tap((categories) => {
                        // @ts-ignore
                        this.selectedCategory = categories.find(cat => cat.id.toString() === param.categoryId);
                        this.resources$ = this.cmsService.getResourcesByCategoryId(this.selectedCategory.id);
                    })
                );
            } else {
                this.categories$ = this.cmsService.getCategories(this.loginService.token).pipe(
                    tap((categories) => {
                        this.selectedCategory = categories.find(cat => cat.order === 1);

                        // @ts-ignore
                        this.resources$ = this.cmsService.getResourcesByCategoryId(this.selectedCategory);
                    })
                );
            }
        });

        //reset datepicker
      this.configService.setDateTo(undefined)
    }

    // tslint:disable-next-line:use-lifecycle-interface
    ngOnChanges() {
        this.translateService.onLangChange.subscribe(lang => {
            this.currentLang = lang.lang;
        });
    }


    openPrintCart(){
        const modalRef = this.modalService.open(CustomPrintCartComponent, {
          size: 'lg'
        });
    }


}
