import {Component, Input, OnInit, Output, SimpleChanges, OnChanges, EventEmitter, ViewChild} from '@angular/core';
import {FilterResourcesPipe} from '../../pipes/filter-resources.pipe';
import {CmsService} from '../../services/cms.service';
import {cloneDeep} from 'lodash';



import {AngularMultiSelect} from '@khajegan/angular2-multiselect-dropdown';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
/*import {log} from 'util';*/


@Component({
    selector: 'app-resources-filter',
    templateUrl: './resources-filter.component.html',
    styleUrls: ['./resources-filter.component.scss'],
    providers: [FilterResourcesPipe]

})
export class ResourcesFilterComponent implements OnInit {

    constructor(private activatedRoute: ActivatedRoute,
                private cmsService: CmsService,
                private filterResourcesPipe: FilterResourcesPipe,
                private translateService: TranslateService) {
    }

    @Input() category;
    @Output() resourcesFiltered = new EventEmitter();

    preselectedIndicator = 0;

    resources;
    filteredResources;
    subIndicators = [];
    sdgs = [];

    currentLang;

    dropdownList = [];
    dropdownSettings = {};


    sdgDropdownSettings;

    filterSettings = {
        searchText: '',
        isMap: true,
        isDocument: true,
        isChart: true,
        isLink: true,
        isStory: true,
        indicators: [],
        selectedItems: [],
        subIndicatorSelected: [],
        subIndicatorSelectedArrayOfString: [],
        sdgSelected: [],
        sdgSelectedArrayOfString: [],
    };

    @ViewChild('dropdownRef', {static: false}) dropdownRef: AngularMultiSelect;
    @ViewChild('dropdownRefSub', {static: false}) dropdownRefSub: AngularMultiSelect;
    @ViewChild('dropdownRefSdg', {static: false}) dropdownRefSdg: AngularMultiSelect;

    ngOnInit() {


        // initialize filter with all indicators selected
        // this.filterSettings.selectedItems = cloneDeep(this.category.indicators);
        // console.log(this.category);

        this.activatedRoute.queryParams.subscribe(params => {
            if (params && params.preselected_indicator) {
                this.preselectedIndicator = parseInt(params.preselected_indicator, 10);
                this.loadPreselectedIndicator();
            }

        });

        this.currentLang = this.translateService.currentLang;
        this.translateService.onLangChange.subscribe(lang => {
            this.currentLang = lang.lang;
        });


    }

    loadResourcesByCategory() {
        this.cmsService.getResourcesByCategoryId(this.category.id).subscribe(
            data => {
                this.resources = data;
                this.filterChanges();
            }
        );
    }

    loadPreselectedIndicator() {
        if  ( this.preselectedIndicator !== 0) {
            this.filterSettings.selectedItems = this.filterSettings.indicators.filter(x => x.id === this.preselectedIndicator);
        } else {
            this.filterSettings.selectedItems = cloneDeep(this.category.indicators);
        }
    }

    resetFilterOnCategoryChanges() {
        // initialize filter with all indicators selected
       this.filterSettings.indicators = cloneDeep(this.category.indicators);
        // initialize filter without indicators selected
        // this.filterSettings.selectedItems = [];
        this.filterSettings.subIndicatorSelected = [];

        this.filterSettings.sdgSelected = [];
    }

    // tslint:disable-next-line:use-lifecycle-interface
    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('category') && changes.category && this.category) {

          this.resetFilterOnCategoryChanges();

          this.loadPreselectedIndicator();

            this.loadResourcesByCategory();
        }
    }

    filterChanges() {
        console.log('apply filter');
        this.filteredResources = this.filterResourcesPipe.transform(this.resources, this.filterSettings);
        this.loadSubIndicators();
        this.resourcesFiltered.emit(this.filteredResources);
    }

    loadSubIndicators() {
        this.subIndicators = [];

        this.subIndicators = this.filteredResources.reduce((acc, value) => {

            value.topics.map(topic => {

              //get topic index
              let index = value.topics.indexOf(topic);


                // const indicator = this.category.indicators.find(ind => ind.name === topic);
                // the problems happens when an indicators of anothers category was assigned to a resource of this category

              //console.log('selected:', this.filterSettings.selectedItems)

                const isInAccumulator = (acc.find(ind => ind.name_en === topic) === undefined);

                const isInFilteredItems = (this.filterSettings.selectedItems.find(ind => ind.name_en === topic) === undefined);

                const isAlmostOne = (this.filterSettings.selectedItems.length !== 0);

                if (isInAccumulator && isInFilteredItems && isAlmostOne) {
                    acc.push({id : acc.length + 1 , name_en: topic, name_ar: value.topics_ar[index]});
                }
            });

            return acc;


        }, []);

        this.sdgs = [];

        this.sdgs = this.filteredResources.reduce((acc, value) => {

            value.sdgs.map(sdg => {

                // const indicator = this.category.indicators.find(ind => ind.name === topic);
                // the problems happens when an indicators of anothers category was assigned to a resource of this category

                const isInAccumulator = (acc.find(ind => ind.name === sdg) === undefined);

                const isInFilteredItems = (this.filterSettings.selectedItems.find(ind => ind.name === sdg) === undefined);

                const isAlmostOne = (this.filterSettings.selectedItems.length !== 0);

                if (isInAccumulator && isInFilteredItems && isAlmostOne) {
           /*       let n = sdg.split(':')[0]
                  let d = n.split('GOAL ')[1]*/
                  let d = sdg.split('_')[1]
                    acc.push({id : acc.length + 1 , name: sdg , order: d});
                }
            });

            try {
                acc.sort((a , b) => {
                    return parseInt(a.order, 10) - parseInt(b.order, 10);
                });
            } catch (e) {
                console.log(e);
            }

            return acc;
        }, []);

    }

    loadSubIndicatorsOfOuterCategory() {// per questa devo cambiare il template
        this.subIndicators = [];

        this.subIndicators = this.filteredResources.reduce((acc, value) => {

            value.topics.map(topic => {

                // tslint:disable-next-line:max-line-length
                if ((acc.indexOf(topic) === -1) && (this.filterSettings.selectedItems.find(ind => ind.name === topic) === undefined) && this.filterSettings.selectedItems.length !== 0) {
                    acc.push(topic);
                }
            });
            return acc;
        }, []);
    }


    onItemSelect(item: any) {
        //this.dropdownRef.closeDropdown(); /*CIUDE IL DROPDOWN DOPO LA SELEZIONE*/
        this.filterChanges();
    }

    OnItemDeSelect(item: any) {
        // console.log(item);
        // console.log(this.selectedItems);
        this.filterChanges();

    }

    onSelectAll(items: any) {
        // console.log(items);
        this.filterChanges();
    }

    onDeSelectAll(items: any) {
        // console.log(items);
        this.filterChanges();
    }


    onSubIndicatorSelect(item: any) {
        //this.dropdownRefSub.closeDropdown();
        this.filterChanges();

    }

    onSdgSelect(item: any) {
        //this.dropdownRefSdg.closeDropdown();
        this.filterChanges();
    }

}
