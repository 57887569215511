import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  ViewChild,
  Output,
  ComponentFactoryResolver, Injector, EventEmitter
} from '@angular/core';
import {CmsService} from '../../services/cms.service';
import {v4 as uuidv4} from 'uuid';
import * as L from 'leaflet';
import {tap, map, filter, distinctUntilKeyChanged, distinctUntilChanged} from 'rxjs/operators';
import 'leaflet-measure';
import 'leaflet-easyprint';
import 'leaflet.gridlayer.googlemutant';




import {environment} from '@environment';
import {InfoPopUpComponentComponent} from '../info-pop-up-component/info-pop-up-component.component';
import {WebgisMap, LayerOnMap, LayersGroup, Layer, DataSource, InfoRequest} from '../../interfaces/webgis';
import * as ts from 'typescript';
import {bounds, control, LeafletEvent, TileLayer} from 'leaflet';

import {WmsClass} from '../../layerManagers/wms.class';
import {ApiService} from '../../services/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LayerGroupChooserComponent} from '../layer-group-chooser/layer-group-chooser.component';
import WmstClass from '../../layerManagers/wmst.class';
import {ConfigService} from '../../services/config.service';
import {TranslateService} from '@ngx-translate/core';
import {forEach} from "lodash";
/*import {isObservable, observable} from "rxjs/internal-compatibility";*/
import {interval, Observable, of, timer} from "rxjs";
import layers = control.layers;


@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
    constructor(public cmsService: CmsService, private componentFactoryResolver: ComponentFactoryResolver,
                private injector: Injector, private apiService: ApiService,
                private modalService: NgbModal, private configService: ConfigService, private translateService: TranslateService) {
        this.uid = uuidv4();
        this.loadingEvent = this.loading();
    }

    environment = environment;

    ClassLayerDict = {
        wms: WmsClass,
        'wms-t': WmstClass
    };

    map: L.Map;

    @Input() mapResourceId;
    @Input() cssClassName: string;
    @Input() isWidget = false;
    @Output() loadingEvent!: Observable<boolean>;

/*   baseLayer: L.TileLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    });*/

  baseLayer = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
    attribution: 'Tiles &copy; Esri &mdash; Source: Esri',
    maxZoom: 24
  });

  // @ts-ignore
  /*googleMap = L.gridLayer.googleMutant({type: 'satellite'});*/

    fullResource;

    measureOptions = {
        position: 'bottomleft',
        // primaryLengthUnit: 'feet',
        primaryLengthUnit: 'kilometers',
        // secondaryLengthUnit: 'miles',
        secondaryLengthUnit: 'miles',
        // primaryAreaUnit: 'acres',
        primaryAreaUnit: 'kilometers',
        // secondaryAreaUnit: 'km'
        secondaryAreaUnit: 'hectares'
    };

    // @ts-ignore
    measureControl = new L.Control.Measure(this.measureOptions);

    uid;
    webgisConfiguration: WebgisMap;
    webgisConfiguration$;

    selectedToolVar;
    infoEnabled = false;
    infoMarkerIcon = L.divIcon({
        html: '<i class="fa fa-crosshairs fa-3x"></i>',
        iconSize: [36, 36],
        className: 'infoMarkerIcon'
    });

    infoMarker;


    wizardStep = 1;
    objectKeys = Object.keys;

    printControl;

    pointerPosition;

    hideShow(id) {
        if (document.getElementById(id).classList.contains('show')) {
            document.getElementById(id).classList.remove('show');
            document.getElementById(id).classList.add('hide');
        } else if (document.getElementById(id).classList.contains('hide')) {
            document.getElementById(id).classList.remove('hide');
            document.getElementById(id).classList.add('show');
        }
    }
    showPanel(id) {
        if (document.getElementById(id).classList.contains('hide')) {
            document.getElementById(id).classList.remove('hide');
            document.getElementById(id).classList.add('show');
        }
    }
    hidePanel(id) {
      if (document.getElementById(id).classList.contains('show')) {
          document.getElementById(id).classList.remove('show');
          document.getElementById(id).classList.add('hide');
      }
    }

    loadLayerFromLayerTreeCallback(obj) {
        this.openLayerGroupChooser(obj);

    }

    selectedTool(tool) {
        this.selectedToolVar = tool;
    }

    getMapObj() {
        return this.map;
    }

    backgroundMapSelected(layer) {
      console.log('test')
        this.map.removeLayer(this.baseLayer);
        this.baseLayer = layer;
        this.map.addLayer(this.baseLayer);
        this.baseLayer.bringToBack();
    }

    setMap() {

        const viewerOptions: L.MapOptions = {
            layers: [this.baseLayer],
            scrollWheelZoom: !this.isWidget
        };
        this.map = L.map(this.uid, viewerOptions);

        //google road default
      this.backgroundMapSelected(this.baseLayer)


        this.map.fitBounds([[38.0, 35.0], [28.0, 53.0]]);

        if (this.isWidget) this.map.setZoom(6); else this.map.setZoom(7);

        this.map.on('mousemove', (e: any) => {
            // console.log(e.latlng.lat.toFixed(2), e.latlng.lng.toFixed(2));
            this.pointerPosition = e;

        });

    }

    hideAll= false
    hideShowAll(){
      this.hideAll = !this.hideAll
      this.objectKeys(this.webgisConfiguration.layers).map((key) => {
        this.webgisConfiguration.layers[key].map((layer) => {
          layer.layerInstance.setOpacity(this.hideAll ? 0 : 1);
        });
      })
    }

    addPrintToMap() {

        const currentView = {
            width: 1024,
            name: 'currentView',
            height: 768,
            className: 'currentView',
            tooltip: 'Current View Port'
        };


        this.printControl = (L as any).easyPrint({
            title: 'Print Me',
            tileLayer: this.baseLayer,
            // position: 'bottomleft',
            sizeModes: [currentView],
            // filename: this.uid,
            exportOnly: true,
            // tileWait: 4000,
            hidden: true,
            hideControlContainer: true
        }).addTo(this.map);
    }

    print() {
        // console.log(this.baseLayer);
        // @ts-ignore
        if (this.baseLayer._url && this.baseLayer._url.indexOf('openstreetmap')) {
            let date = new Date()
          let filename = 'Print-'+(date.getMonth()+1).toString()+'-' + date.getDate().toString()+'-' + date.getFullYear().toString()+'|' + date.getHours().toString()+':' + date.getMinutes().toString()+':'+date.getSeconds().toString()
            this.printControl.printMap('currentView', filename);
        } else {
            alert(this.translateService.instant('pages.map.alert_baseLayer_onPrint'));
        }

    }

    addMapControl() {
        this.measureControl.addTo(this.map);
    }

    removeLayer(layer, group, index) {
        // remove from map
        layer.layerInstance.remove();
        // remove from confObj
        this.removeLayerFromConfiguration(group, index);
    }

    removeLayerFromConfiguration(group, index) {
        // @ts-ignore
        this.webgisConfiguration.layers[group].splice(index, 1);
        // @ts-ignore
        if (this.webgisConfiguration.layers[group].length === 0) {
            delete this.webgisConfiguration.layers[group];
        }
    }


    setBBOX() {
        try {
            //console.log('set bbox', this.webgisConfiguration.home_extent);
            const bbox = this.webgisConfiguration.home_extent.split(',');
            // @ts-ignore
            this.map.fitBounds([[bbox[0], bbox[1]], [bbox[2], bbox[3]]]);
           // this.map.fitBounds([['37.00', '37.00'], ['29.00', '50.00']]);
        } catch (e) {
            console.log(e);
        }

    }

    addInfoEventOnClick() {
        this.map.on('click', (evt) => {
            console.log(evt);
            if (this.infoEnabled === false) { return; }

            // @ts-ignore
            this.infoMarker = L.marker(evt.latlng, { icon:  this.infoMarkerIcon}).addTo(this.map)
                .bindPopup(this.createCustomPopup(this.webgisConfiguration, evt), {minWidth: 500, maxHeight: 400}).openPopup();

            this.infoMarker.getPopup().on('remove', () => this.infoMarker.remove());

        });
    }

    createCustomPopup(data, event) {
        const factory = this.componentFactoryResolver.resolveComponentFactory(InfoPopUpComponentComponent);
        const component = factory.create(this.injector);

        // Set the component inputs manually
        component.instance.webGisConfiguration = this.webgisConfiguration;
        component.instance.event = event;

        //  Subscribe to the components outputs manually (if any)
        component.instance.callBack.subscribe(() => console.log('output handler fired'));

        // Manually invoke change detection, automatic wont work, but this is Ok if the component doesn't change
        component.changeDetectorRef.detectChanges();

        // setTimeout(() => {
        //     component.changeDetectorRef.detectChanges();
        // },5000);

        return component.location.nativeElement;
    }

    // buildDataForInfo(evt) {
    //     // const keys = Object.keys(this.webgisConfiguration.layers);
    //     return Object.keys(this.webgisConfiguration.layers).map(key => {
    //         return this.webgisConfiguration.layers[key].map(layer => {
    //             return layer.layerInstance.infoData(evt);
    //         });
    //     });
    // }

    openLayerGroupChooser(obj) {
        const modalRef = this.modalService.open(LayerGroupChooserComponent);
        modalRef.componentInstance.webgisConfiguration = this.webgisConfiguration;
        // modalRef.componentInstance.selected.subscribe(
        //     (data) => {
        //         console.log(data);
        //     }
        // );

        modalRef.result.then(layersGroup => {
            // @ts-ignore
            this.addNewLayerToMap(obj.layer, layersGroup);
            // this.webgisConfiguration.layers[data].push(obj.layer);
        });

    }

    loadLayersInConfiguration() {
        // tslint:disable-next-line:forin
        for (const layerGroupId in this.webgisConfiguration.layers) {
            // @ts-ignore
            this.webgisConfiguration.layers[layerGroupId].map((layer: LayerOnMap ) => {
                layer.layerInstance = new (this.ClassLayerDict[layer.layer.type])(layer, this.map, this.apiService, this.configService);
            });
        }




    }

    mapIsLoading = true

    addNewLayerToMap(layer, layerGroup) {
        const layerObjectNew = {
            layer,
            layerInstance: null,
            layers_group: layerGroup
        };

        //console.log('aggiungo layer', layer)

        layer.layerInstance = new (this.ClassLayerDict[layer.type])(layerObjectNew, this.map, this.apiService, this.configService);

        // @ts-ignore
        if ( this.webgisConfiguration.layers[layerGroup.name]) {
            // @ts-ignore
            this.webgisConfiguration.layers[layerGroup.name].push(layer);
        } else {
            // @ts-ignore
            this.webgisConfiguration.layers[layerGroup.name] = [layer];
        }


    }


  loading(): Observable<boolean> {
      const timer = interval(1000)
        .pipe(
          filter(() => this.map !== undefined),
          map(() => {
            const items = [];
            this.map.eachLayer(layer => items.push(layer));
            return items;

          }),

          map(layers => layers.filter(l=>l._loading === true)),
          map(layers => layers.length),
          map(length => length>0),
          distinctUntilChanged(),
      );
      return timer;
  }

    ngOnInit() {

        this.cmsService.getWebgisById(this.mapResourceId).subscribe((data) => {
            this.fullResource = data;
        });

        this.webgisConfiguration$ = this.cmsService.getWebgisMap(this.mapResourceId).pipe(tap(
            data => {
                this.webgisConfiguration = data;
                //console.log('conf', data)
                this.setMap();
                setTimeout(x=>{this.setBBOX()}, 1000) //fa saltare il fit - non so a cosa serva


              /*SET BBOX TO LAYER*/
    /*          console.log('BOX', data)
              const bbox = data.home_extent.split(',');
              // @ts-ignore
              if (bbox.length == 4) this.map.fitBounds([[bbox[0], bbox[1]], [bbox[2], bbox[3]]]);

              this.setBBOX()
*/


                if (this.isWidget !== true) {
                    this.addMapControl();
                    this.addPrintToMap();
                    this.addInfoEventOnClick();
                }


                this.loadLayersInConfiguration();

            }
        ));

        //set google map default
      //this.backgroundMapSelected(this.googleMap)


    }

    // tslint:disable-next-line:use-lifecycle-interface
    ngAfterViewInit() {

    }

    // tslint:disable-next-line:use-lifecycle-interface
    ngOnChanges(changes: SimpleChanges) {

    }

}
