import {AfterViewInit, Component, Input, OnInit, SimpleChanges} from '@angular/core';
import * as ChartJs from 'chart.js';
import { v4 as uuidv4 } from 'uuid';
import {Chart} from '../../../interfaces/chart';
import {SerieService} from '../../../services/serie.service';
import * as _ from 'lodash';
import {ConfigService} from '../../../services/config.service';
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'app-stackedbar-chart',
  templateUrl: './stackedbar-chart.component.html',
  styleUrls: ['./stackedbar-chart.component.scss']
})
export class StackedbarChartComponent implements AfterViewInit {

  @Input() chartObj: Chart;

  uid;
  canvas;
  ctx;
  Chart;
  series;
  currentLang;
  parsedData;

  ngOnInit() {
   // console.log('data STACKED', this.chartObj)
  }

  loadingChart = false


  constructor(private serieService: SerieService, private configService: ConfigService, private translateService: TranslateService) {
    this.uid = uuidv4();
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnChanges(change: SimpleChanges) {
    if (change.chartObj) {
      this.loadSeries();

    }

  }

  canPrint() {
    return this.configService.canPrintChart();
  }
  canExportData() {
    return this.configService.canDowloadCsv();
  }
  exportData() {

    // Helper per eseguire l'escape delle stringhe
    const escapeCsvValue = (value: string | number) => {
      const stringValue = String(value);
      if (stringValue.includes(',') || stringValue.includes('"') || stringValue.includes('\n')) {
        // Raddoppia i doppi apici e racchiudi tutto tra doppi apici
        return `"${stringValue.replace(/"/g, '""')}"`;
      }
      return stringValue;
    };

    // Inizializza il contenuto del CSV con l'intestazione
    let csvContent = 'data:text/csv;charset=utf-8,\n';
    csvContent += ['Label', ...this.parsedData.labels.map(escapeCsvValue)].join(',') + '\n';

    // Aggiungi i dati dei dataset riga per riga, preceduti dalla rispettiva label
    this.parsedData.datasets.forEach(dataset => {
      csvContent += [escapeCsvValue(dataset.label), ...dataset.data.map(escapeCsvValue)].join(',') + '\n';
    });

    // Chiama il metodo per scaricare il CSV
    this.configService.downloadDataInCsv(csvContent, this.chartObj['name_' + this.currentLang]);
  }

  print(id) {
    this.configService.print(id , this.chartObj.name_en, '#fff' );
  }

  ngAfterViewInit() {
    //console.log('load stacked chart');
    this.currentLang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(lang => {
      this.currentLang = lang.lang;
    })
  }


  parseData(selectedSeries) {


    //console.log('SERIE SELEZ', selectedSeries);

    const labels = Object.keys(selectedSeries.values);
    const datasets = [];

    let backgroundColor = [];

    if (this.chartObj.configuration.hasOwnProperty('colors')) {
      backgroundColor = this.chartObj.configuration.colors;
    } else {
      selectedSeries.labels.map(serie => {
        backgroundColor.push('#' + (0x1000000 + (Math.random()) * 0xffffff).toString(16).substr(1, 6));
      });
    }

    selectedSeries.labels.map((v, i) => {
      const dataset = {
        label: v,
        data: [],
        backgroundColor: backgroundColor[i]

      };

      Object.entries(selectedSeries.values).map(area => {
        dataset.data.push(selectedSeries.values[area[0]][i].toFixed(1));
      });

      datasets.push(dataset);
    });


    this.parsedData = {
      labels,
      datasets
    };
  }

  loadSeries() {
    this.loadingChart = true
    this.serieService.getSerieById(this.chartObj.configuration.serie).subscribe(
        data => {
          this.series = data[0].data;
          this.loadingChart = false
          //se serie contiene più oggetti
          if (this.series.length>0) {
            this.selectedSeries = this.series[0].name
            this.parseData(this.series[0])
          }
          //altrimenti carico grafico semplice
          else this.parseData(this.series)
          this.loadChart();
        }
    );
  }

  selectedSeries
  changeSerie(newSerieName){
    this.parsedData = []
    let newSerie = this.series.find(x => x.name == newSerieName)
    this.parseData(newSerie)
    this.loadChart();
  }


  loadChart() {

    let displayLegend: boolean = this.parsedData.datasets.length > 1

    if(this.Chart!=null){
      this.Chart.destroy();
    }

    this.canvas = document.getElementById(this.uid);
    this.ctx = this.canvas.getContext('2d');
    this.Chart = new ChartJs(this.ctx, {
      type: 'bar',
      data: this.parsedData,
      options: {
      tooltips: {
        enabled: true,
        mode: 'nearest',
        callbacks:  {
          label: (tooltipItems, data) => {
            // console.log(data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]);
            // console.log(this.chartObj.configuration.hasOwnProperty('um'));
            const um = (this.chartObj.configuration.hasOwnProperty('um')) ? this.chartObj.configuration.um : '';
            return data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] + ' ' + um;
          }
        }
      },
        scales: {
          xAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              display: false,
            },
            scaleLabel: {
              display: (this.chartObj.configuration.hasOwnProperty('label_x')),
              labelString: (this.chartObj.configuration.hasOwnProperty('label_x')) ? this.chartObj.configuration.label_x : ''
            }
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true,
            },
            type: 'linear',
            scaleLabel: {
              display: (this.chartObj.configuration.hasOwnProperty('label_y')),
              labelString: (this.chartObj.configuration.hasOwnProperty('label_y')) ? this.chartObj.configuration.label_y : ''
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: { position: 'bottom', display: displayLegend },
      }
    });
    //
    // "label_x": "",
    //     "label_y": "",
    //     "um": "kmq"

  }
}
